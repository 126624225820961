@import "../../../styles/vars.scss";
@import "../../../styles/media.scss";

.social-media-links {
    $clr: #1d96e8;
    $rClr: darken($primary-clr, 10);// darken($clr, 10);
    background: $primary-clr;
    color: $white;
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: center;
    perspective: 1000px;

    > div.content {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            margin-right: 20px;
        }

        @include maxBreakpoint($bp: 417px) {
            flex-direction: column;
            > p {
                margin: 0;
                margin-bottom: 1rem;
            }
        }


    }

    .sm-icon {
        margin: 0 5px;
        border-radius: 50%;
        border: 2px solid $rClr;
        color: $rClr;
        box-sizing: border-box;
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 45px;
        text-align: center;
        background: transparent;
        @include transition;
        transform-style: preserve-3d;
        svg {
            font-size: 18px;
        }
        &:hover {
            border: 2px solid $clr;
            color: $clr;
            // svg {
            //     transform: perspective(100px) rotateX(25deg) rotateY(0deg) rotateZ(-15deg);
            //     font-size: 25px;
            //     margin-top: 10px;
            // }
        }
    }
}



// * Icon mapping
//  ========================================================================== */
// .uk-icon-glass:before {
//   content: "\f000";
// }
// .uk-icon-music:before {
//   content: "\f001";
// }
// .uk-icon-search:before {
//   content: "\f002";
// }
// .uk-icon-envelope-o:before {
//   content: "\f003";
// }
// .uk-icon-heart:before {
//   content: "\f004";
// }
// .uk-icon-star:before {
//   content: "\f005";
// }
// .uk-icon-star-o:before {
//   content: "\f006";
// }
// .uk-icon-user:before {
//   content: "\f007";
// }
// .uk-icon-film:before {
//   content: "\f008";
// }
// .uk-icon-th-large:before {
//   content: "\f009";
// }
// .uk-icon-th:before {
//   content: "\f00a";
// }
// .uk-icon-th-list:before {
//   content: "\f00b";
// }
// .uk-icon-check:before {
//   content: "\f00c";
// }
// .uk-icon-remove:before,
// .uk-icon-close:before,
// .uk-icon-times:before {
//   content: "\f00d";
// }
// .uk-icon-search-plus:before {
//   content: "\f00e";
// }
// .uk-icon-search-minus:before {
//   content: "\f010";
// }
// .uk-icon-power-off:before {
//   content: "\f011";
// }
// .uk-icon-signal:before {
//   content: "\f012";
// }
// .uk-icon-gear:before,
// .uk-icon-cog:before {
//   content: "\f013";
// }
// .uk-icon-trash-o:before {
//   content: "\f014";
// }
// .uk-icon-home:before {
//   content: "\f015";
// }
// .uk-icon-file-o:before {
//   content: "\f016";
// }
// .uk-icon-clock-o:before {
//   content: "\f017";
// }
// .uk-icon-road:before {
//   content: "\f018";
// }
// .uk-icon-download:before {
//   content: "\f019";
// }
// .uk-icon-arrow-circle-o-down:before {
//   content: "\f01a";
// }
// .uk-icon-arrow-circle-o-up:before {
//   content: "\f01b";
// }
// .uk-icon-inbox:before {
//   content: "\f01c";
// }
// .uk-icon-play-circle-o:before {
//   content: "\f01d";
// }
// .uk-icon-rotate-right:before,
// .uk-icon-repeat:before {
//   content: "\f01e";
// }
// .uk-icon-refresh:before {
//   content: "\f021";
// }
// .uk-icon-list-alt:before {
//   content: "\f022";
// }
// .uk-icon-lock:before {
//   content: "\f023";
// }
// .uk-icon-flag:before {
//   content: "\f024";
// }
// .uk-icon-headphones:before {
//   content: "\f025";
// }
// .uk-icon-volume-off:before {
//   content: "\f026";
// }
// .uk-icon-volume-down:before {
//   content: "\f027";
// }
// .uk-icon-volume-up:before {
//   content: "\f028";
// }
// .uk-icon-qrcode:before {
//   content: "\f029";
// }
// .uk-icon-barcode:before {
//   content: "\f02a";
// }
// .uk-icon-tag:before {
//   content: "\f02b";
// }
// .uk-icon-tags:before {
//   content: "\f02c";
// }
// .uk-icon-book:before {
//   content: "\f02d";
// }
// .uk-icon-bookmark:before {
//   content: "\f02e";
// }
// .uk-icon-print:before {
//   content: "\f02f";
// }
// .uk-icon-camera:before {
//   content: "\f030";
// }
// .uk-icon-font:before {
//   content: "\f031";
// }
// .uk-icon-bold:before {
//   content: "\f032";
// }
// .uk-icon-italic:before {
//   content: "\f033";
// }
// .uk-icon-text-height:before {
//   content: "\f034";
// }
// .uk-icon-text-width:before {
//   content: "\f035";
// }
// .uk-icon-align-left:before {
//   content: "\f036";
// }
// .uk-icon-align-center:before {
//   content: "\f037";
// }
// .uk-icon-align-right:before {
//   content: "\f038";
// }
// .uk-icon-align-justify:before {
//   content: "\f039";
// }
// .uk-icon-list:before {
//   content: "\f03a";
// }
// .uk-icon-dedent:before,
// .uk-icon-outdent:before {
//   content: "\f03b";
// }
// .uk-icon-indent:before {
//   content: "\f03c";
// }
// .uk-icon-video-camera:before {
//   content: "\f03d";
// }
// .uk-icon-photo:before,
// .uk-icon-image:before,
// .uk-icon-picture-o:before {
//   content: "\f03e";
// }
// .uk-icon-pencil:before {
//   content: "\f040";
// }
// .uk-icon-map-marker:before {
//   content: "\f041";
// }
// .uk-icon-adjust:before {
//   content: "\f042";
// }
// .uk-icon-tint:before {
//   content: "\f043";
// }
// .uk-icon-edit:before,
// .uk-icon-pencil-square-o:before {
//   content: "\f044";
// }
// .uk-icon-share-square-o:before {
//   content: "\f045";
// }
// .uk-icon-check-square-o:before {
//   content: "\f046";
// }
// .uk-icon-arrows:before {
//   content: "\f047";
// }
// .uk-icon-step-backward:before {
//   content: "\f048";
// }
// .uk-icon-fast-backward:before {
//   content: "\f049";
// }
// .uk-icon-backward:before {
//   content: "\f04a";
// }
// .uk-icon-play:before {
//   content: "\f04b";
// }
// .uk-icon-pause:before {
//   content: "\f04c";
// }
// .uk-icon-stop:before {
//   content: "\f04d";
// }
// .uk-icon-forward:before {
//   content: "\f04e";
// }
// .uk-icon-fast-forward:before {
//   content: "\f050";
// }
// .uk-icon-step-forward:before {
//   content: "\f051";
// }
// .uk-icon-eject:before {
//   content: "\f052";
// }
// .uk-icon-chevron-left:before {
//   content: "\f053";
// }
// .uk-icon-chevron-right:before {
//   content: "\f054";
// }
// .uk-icon-plus-circle:before {
//   content: "\f055";
// }
// .uk-icon-minus-circle:before {
//   content: "\f056";
// }
// .uk-icon-times-circle:before {
//   content: "\f057";
// }
// .uk-icon-check-circle:before {
//   content: "\f058";
// }
// .uk-icon-question-circle:before {
//   content: "\f059";
// }
// .uk-icon-info-circle:before {
//   content: "\f05a";
// }
// .uk-icon-crosshairs:before {
//   content: "\f05b";
// }
// .uk-icon-times-circle-o:before {
//   content: "\f05c";
// }
// .uk-icon-check-circle-o:before {
//   content: "\f05d";
// }
// .uk-icon-ban:before {
//   content: "\f05e";
// }
// .uk-icon-arrow-left:before {
//   content: "\f060";
// }
// .uk-icon-arrow-right:before {
//   content: "\f061";
// }
// .uk-icon-arrow-up:before {
//   content: "\f062";
// }
// .uk-icon-arrow-down:before {
//   content: "\f063";
// }
// .uk-icon-mail-forward:before,
// .uk-icon-share:before {
//   content: "\f064";
// }
// .uk-icon-expand:before {
//   content: "\f065";
// }
// .uk-icon-compress:before {
//   content: "\f066";
// }
// .uk-icon-plus:before {
//   content: "\f067";
// }
// .uk-icon-minus:before {
//   content: "\f068";
// }
// .uk-icon-asterisk:before {
//   content: "\f069";
// }
// .uk-icon-exclamation-circle:before {
//   content: "\f06a";
// }
// .uk-icon-gift:before {
//   content: "\f06b";
// }
// .uk-icon-leaf:before {
//   content: "\f06c";
// }
// .uk-icon-fire:before {
//   content: "\f06d";
// }
// .uk-icon-eye:before {
//   content: "\f06e";
// }
// .uk-icon-eye-slash:before {
//   content: "\f070";
// }
// .uk-icon-warning:before,
// .uk-icon-exclamation-triangle:before {
//   content: "\f071";
// }
// .uk-icon-plane:before {
//   content: "\f072";
// }
// .uk-icon-calendar:before {
//   content: "\f073";
// }
// .uk-icon-random:before {
//   content: "\f074";
// }
// .uk-icon-comment:before {
//   content: "\f075";
// }
// .uk-icon-magnet:before {
//   content: "\f076";
// }
// .uk-icon-chevron-up:before {
//   content: "\f077";
// }
// .uk-icon-chevron-down:before {
//   content: "\f078";
// }
// .uk-icon-retweet:before {
//   content: "\f079";
// }
// .uk-icon-shopping-cart:before {
//   content: "\f07a";
// }
// .uk-icon-folder:before {
//   content: "\f07b";
// }
// .uk-icon-folder-open:before {
//   content: "\f07c";
// }
// .uk-icon-arrows-v:before {
//   content: "\f07d";
// }
// .uk-icon-arrows-h:before {
//   content: "\f07e";
// }
// .uk-icon-bar-chart-o:before,
// .uk-icon-bar-chart:before {
//   content: "\f080";
// }
// .uk-icon-twitter-square:before {
//   content: "\f081";
// }
// .uk-icon-facebook-square:before {
//   content: "\f082";
// }
// .uk-icon-camera-retro:before {
//   content: "\f083";
// }
// .uk-icon-key:before {
//   content: "\f084";
// }
// .uk-icon-gears:before,
// .uk-icon-cogs:before {
//   content: "\f085";
// }
// .uk-icon-comments:before {
//   content: "\f086";
// }
// .uk-icon-thumbs-o-up:before {
//   content: "\f087";
// }
// .uk-icon-thumbs-o-down:before {
//   content: "\f088";
// }
// .uk-icon-star-half:before {
//   content: "\f089";
// }
// .uk-icon-heart-o:before {
//   content: "\f08a";
// }
// .uk-icon-sign-out:before {
//   content: "\f08b";
// }
// .uk-icon-linkedin-square:before {
//   content: "\f08c";
// }
// .uk-icon-thumb-tack:before {
//   content: "\f08d";
// }
// .uk-icon-external-link:before {
//   content: "\f08e";
// }
// .uk-icon-sign-in:before {
//   content: "\f090";
// }
// .uk-icon-trophy:before {
//   content: "\f091";
// }
// .uk-icon-github-square:before {
//   content: "\f092";
// }
// .uk-icon-upload:before {
//   content: "\f093";
// }
// .uk-icon-lemon-o:before {
//   content: "\f094";
// }
// .uk-icon-phone:before {
//   content: "\f095";
// }
// .uk-icon-square-o:before {
//   content: "\f096";
// }
// .uk-icon-bookmark-o:before {
//   content: "\f097";
// }
// .uk-icon-phone-square:before {
//   content: "\f098";
// }
// .uk-icon-twitter:before {
//   content: "\f099";
// }
// .uk-icon-facebook-f:before,
// .uk-icon-facebook:before {
//   content: "\f09a";
// }
// .uk-icon-github:before {
//   content: "\f09b";
// }
// .uk-icon-unlock:before {
//   content: "\f09c";
// }
// .uk-icon-credit-card:before {
//   content: "\f09d";
// }
// .uk-icon-rss:before {
//   content: "\f09e";
// }
// .uk-icon-hdd-o:before {
//   content: "\f0a0";
// }
// .uk-icon-bullhorn:before {
//   content: "\f0a1";
// }
// .uk-icon-bell:before {
//   content: "\f0f3";
// }
// .uk-icon-certificate:before {
//   content: "\f0a3";
// }
// .uk-icon-hand-o-right:before {
//   content: "\f0a4";
// }
// .uk-icon-hand-o-left:before {
//   content: "\f0a5";
// }
// .uk-icon-hand-o-up:before {
//   content: "\f0a6";
// }
// .uk-icon-hand-o-down:before {
//   content: "\f0a7";
// }
// .uk-icon-arrow-circle-left:before {
//   content: "\f0a8";
// }
// .uk-icon-arrow-circle-right:before {
//   content: "\f0a9";
// }
// .uk-icon-arrow-circle-up:before {
//   content: "\f0aa";
// }
// .uk-icon-arrow-circle-down:before {
//   content: "\f0ab";
// }
// .uk-icon-globe:before {
//   content: "\f0ac";
// }
// .uk-icon-wrench:before {
//   content: "\f0ad";
// }
// .uk-icon-tasks:before {
//   content: "\f0ae";
// }
// .uk-icon-filter:before {
//   content: "\f0b0";
// }
// .uk-icon-briefcase:before {
//   content: "\f0b1";
// }
// .uk-icon-arrows-alt:before {
//   content: "\f0b2";
// }
// .uk-icon-group:before,
// .uk-icon-users:before {
//   content: "\f0c0";
// }
// .uk-icon-chain:before,
// .uk-icon-link:before {
//   content: "\f0c1";
// }
// .uk-icon-cloud:before {
//   content: "\f0c2";
// }
// .uk-icon-flask:before {
//   content: "\f0c3";
// }
// .uk-icon-cut:before,
// .uk-icon-scissors:before {
//   content: "\f0c4";
// }
// .uk-icon-copy:before,
// .uk-icon-files-o:before {
//   content: "\f0c5";
// }
// .uk-icon-paperclip:before {
//   content: "\f0c6";
// }
// .uk-icon-save:before,
// .uk-icon-floppy-o:before {
//   content: "\f0c7";
// }
// .uk-icon-square:before {
//   content: "\f0c8";
// }
// .uk-icon-navicon:before,
// .uk-icon-reorder:before,
// .uk-icon-bars:before {
//   content: "\f0c9";
// }
// .uk-icon-list-ul:before {
//   content: "\f0ca";
// }
// .uk-icon-list-ol:before {
//   content: "\f0cb";
// }
// .uk-icon-strikethrough:before {
//   content: "\f0cc";
// }
// .uk-icon-underline:before {
//   content: "\f0cd";
// }
// .uk-icon-table:before {
//   content: "\f0ce";
// }
// .uk-icon-magic:before {
//   content: "\f0d0";
// }
// .uk-icon-truck:before {
//   content: "\f0d1";
// }
// .uk-icon-pinterest:before {
//   content: "\f0d2";
// }
// .uk-icon-pinterest-square:before {
//   content: "\f0d3";
// }
// .uk-icon-google-plus-square:before {
//   content: "\f0d4";
// }
// .uk-icon-google-plus:before {
//   content: "\f0d5";
// }
// .uk-icon-money:before {
//   content: "\f0d6";
// }
// .uk-icon-caret-down:before {
//   content: "\f0d7";
// }
// .uk-icon-caret-up:before {
//   content: "\f0d8";
// }
// .uk-icon-caret-left:before {
//   content: "\f0d9";
// }
// .uk-icon-caret-right:before {
//   content: "\f0da";
// }
// .uk-icon-columns:before {
//   content: "\f0db";
// }
// .uk-icon-unsorted:before,
// .uk-icon-sort:before {
//   content: "\f0dc";
// }
// .uk-icon-sort-down:before,
// .uk-icon-sort-desc:before {
//   content: "\f0dd";
// }
// .uk-icon-sort-up:before,
// .uk-icon-sort-asc:before {
//   content: "\f0de";
// }
// .uk-icon-envelope:before {
//   content: "\f0e0";
// }
// .uk-icon-linkedin:before {
//   content: "\f0e1";
// }
// .uk-icon-rotate-left:before,
// .uk-icon-undo:before {
//   content: "\f0e2";
// }
// .uk-icon-legal:before,
// .uk-icon-gavel:before {
//   content: "\f0e3";
// }
// .uk-icon-dashboard:before,
// .uk-icon-tachometer:before {
//   content: "\f0e4";
// }
// .uk-icon-comment-o:before {
//   content: "\f0e5";
// }
// .uk-icon-comments-o:before {
//   content: "\f0e6";
// }
// .uk-icon-flash:before,
// .uk-icon-bolt:before {
//   content: "\f0e7";
// }
// .uk-icon-sitemap:before {
//   content: "\f0e8";
// }
// .uk-icon-umbrella:before {
//   content: "\f0e9";
// }
// .uk-icon-paste:before,
// .uk-icon-clipboard:before {
//   content: "\f0ea";
// }
// .uk-icon-lightbulb-o:before {
//   content: "\f0eb";
// }
// .uk-icon-exchange:before {
//   content: "\f0ec";
// }
// .uk-icon-cloud-download:before {
//   content: "\f0ed";
// }
// .uk-icon-cloud-upload:before {
//   content: "\f0ee";
// }
// .uk-icon-user-md:before {
//   content: "\f0f0";
// }
// .uk-icon-stethoscope:before {
//   content: "\f0f1";
// }
// .uk-icon-suitcase:before {
//   content: "\f0f2";
// }
// .uk-icon-bell-o:before {
//   content: "\f0a2";
// }
// .uk-icon-coffee:before {
//   content: "\f0f4";
// }
// .uk-icon-cutlery:before {
//   content: "\f0f5";
// }
// .uk-icon-file-text-o:before {
//   content: "\f0f6";
// }
// .uk-icon-building-o:before {
//   content: "\f0f7";
// }
// .uk-icon-hospital-o:before {
//   content: "\f0f8";
// }
// .uk-icon-ambulance:before {
//   content: "\f0f9";
// }
// .uk-icon-medkit:before {
//   content: "\f0fa";
// }
// .uk-icon-fighter-jet:before {
//   content: "\f0fb";
// }
// .uk-icon-beer:before {
//   content: "\f0fc";
// }
// .uk-icon-h-square:before {
//   content: "\f0fd";
// }
// .uk-icon-plus-square:before {
//   content: "\f0fe";
// }
// .uk-icon-angle-double-left:before {
//   content: "\f100";
// }
// .uk-icon-angle-double-right:before {
//   content: "\f101";
// }
// .uk-icon-angle-double-up:before {
//   content: "\f102";
// }
// .uk-icon-angle-double-down:before {
//   content: "\f103";
// }
// .uk-icon-angle-left:before {
//   content: "\f104";
// }
// .uk-icon-angle-right:before {
//   content: "\f105";
// }
// .uk-icon-angle-up:before {
//   content: "\f106";
// }
// .uk-icon-angle-down:before {
//   content: "\f107";
// }
// .uk-icon-desktop:before {
//   content: "\f108";
// }
// .uk-icon-laptop:before {
//   content: "\f109";
// }
// .uk-icon-tablet:before {
//   content: "\f10a";
// }
// .uk-icon-mobile-phone:before,
// .uk-icon-mobile:before {
//   content: "\f10b";
// }
// .uk-icon-circle-o:before {
//   content: "\f10c";
// }
// .uk-icon-quote-left:before {
//   content: "\f10d";
// }
// .uk-icon-quote-right:before {
//   content: "\f10e";
// }
// .uk-icon-spinner:before {
//   content: "\f110";
// }
// .uk-icon-circle:before {
//   content: "\f111";
// }
// .uk-icon-mail-reply:before,
// .uk-icon-reply:before {
//   content: "\f112";
// }
// .uk-icon-github-alt:before {
//   content: "\f113";
// }
// .uk-icon-folder-o:before {
//   content: "\f114";
// }
// .uk-icon-folder-open-o:before {
//   content: "\f115";
// }
// .uk-icon-smile-o:before {
//   content: "\f118";
// }
// .uk-icon-frown-o:before {
//   content: "\f119";
// }
// .uk-icon-meh-o:before {
//   content: "\f11a";
// }
// .uk-icon-gamepad:before {
//   content: "\f11b";
// }
// .uk-icon-keyboard-o:before {
//   content: "\f11c";
// }
// .uk-icon-flag-o:before {
//   content: "\f11d";
// }
// .uk-icon-flag-checkered:before {
//   content: "\f11e";
// }
// .uk-icon-terminal:before {
//   content: "\f120";
// }
// .uk-icon-code:before {
//   content: "\f121";
// }
// .uk-icon-mail-reply-all:before,
// .uk-icon-reply-all:before {
//   content: "\f122";
// }
// .uk-icon-star-half-empty:before,
// .uk-icon-star-half-full:before,
// .uk-icon-star-half-o:before {
//   content: "\f123";
// }
// .uk-icon-location-arrow:before {
//   content: "\f124";
// }
// .uk-icon-crop:before {
//   content: "\f125";
// }
// .uk-icon-code-fork:before {
//   content: "\f126";
// }
// .uk-icon-unlink:before,
// .uk-icon-chain-broken:before {
//   content: "\f127";
// }
// .uk-icon-question:before {
//   content: "\f128";
// }
// .uk-icon-info:before {
//   content: "\f129";
// }
// .uk-icon-exclamation:before {
//   content: "\f12a";
// }
// .uk-icon-superscript:before {
//   content: "\f12b";
// }
// .uk-icon-subscript:before {
//   content: "\f12c";
// }
// .uk-icon-eraser:before {
//   content: "\f12d";
// }
// .uk-icon-puzzle-piece:before {
//   content: "\f12e";
// }
// .uk-icon-microphone:before {
//   content: "\f130";
// }
// .uk-icon-microphone-slash:before {
//   content: "\f131";
// }
// .uk-icon-shield:before {
//   content: "\f132";
// }
// .uk-icon-calendar-o:before {
//   content: "\f133";
// }
// .uk-icon-fire-extinguisher:before {
//   content: "\f134";
// }
// .uk-icon-rocket:before {
//   content: "\f135";
// }
// .uk-icon-maxcdn:before {
//   content: "\f136";
// }
// .uk-icon-chevron-circle-left:before {
//   content: "\f137";
// }
// .uk-icon-chevron-circle-right:before {
//   content: "\f138";
// }
// .uk-icon-chevron-circle-up:before {
//   content: "\f139";
// }
// .uk-icon-chevron-circle-down:before {
//   content: "\f13a";
// }
// .uk-icon-html5:before {
//   content: "\f13b";
// }
// .uk-icon-css3:before {
//   content: "\f13c";
// }
// .uk-icon-anchor:before {
//   content: "\f13d";
// }
// .uk-icon-unlock-alt:before {
//   content: "\f13e";
// }
// .uk-icon-bullseye:before {
//   content: "\f140";
// }
// .uk-icon-ellipsis-h:before {
//   content: "\f141";
// }
// .uk-icon-ellipsis-v:before {
//   content: "\f142";
// }
// .uk-icon-rss-square:before {
//   content: "\f143";
// }
// .uk-icon-play-circle:before {
//   content: "\f144";
// }
// .uk-icon-ticket:before {
//   content: "\f145";
// }
// .uk-icon-minus-square:before {
//   content: "\f146";
// }
// .uk-icon-minus-square-o:before {
//   content: "\f147";
// }
// .uk-icon-level-up:before {
//   content: "\f148";
// }
// .uk-icon-level-down:before {
//   content: "\f149";
// }
// .uk-icon-check-square:before {
//   content: "\f14a";
// }
// .uk-icon-pencil-square:before {
//   content: "\f14b";
// }
// .uk-icon-external-link-square:before {
//   content: "\f14c";
// }
// .uk-icon-share-square:before {
//   content: "\f14d";
// }
// .uk-icon-compass:before {
//   content: "\f14e";
// }
// .uk-icon-toggle-down:before,
// .uk-icon-caret-square-o-down:before {
//   content: "\f150";
// }
// .uk-icon-toggle-up:before,
// .uk-icon-caret-square-o-up:before {
//   content: "\f151";
// }
// .uk-icon-toggle-right:before,
// .uk-icon-caret-square-o-right:before {
//   content: "\f152";
// }
// .uk-icon-euro:before,
// .uk-icon-eur:before {
//   content: "\f153";
// }
// .uk-icon-gbp:before {
//   content: "\f154";
// }
// .uk-icon-dollar:before,
// .uk-icon-usd:before {
//   content: "\f155";
// }
// .uk-icon-rupee:before,
// .uk-icon-inr:before {
//   content: "\f156";
// }