@import "../../../../styles/vars";



.home-contact-link {
    background: lighten($primary-clr, 15);
    color: white;
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: center;
    
    
    p > span {
        font-size: 1.4rem;
        color: red;
        &:hover {
            cursor: pointer;
        }
    }

}