@import "../../../../../styles/vars";
@import "../../../../../styles/media";
.quick-links {
    position: relative;
    a {
        color: white;
        position: relative;
        &.active {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                // background: $primary-clr;
                height: 100%;
                top: 0;
                // left: -50%;
                // transform: translateX(50px);
            }
            span {
                position: relative;
                z-index: 1;
            }
        }
    }
    .footer-nav-links {
        h1 {
            text-align: center;
            font-size: 2rem;
            padding-bottom: 2rem;
        }
        .links {
            display: flex;
            flex-wrap: wrap;

            > div {
                width: 50%;
                height: 40px;
                display: flex;
                align-items: center;
                > a {
                    width: 80%;
                    padding: 5px 0;
                    @include transition;
                    color: darken(#fff, 20);
                    &:hover {
                        color: $white;
                    }
                    &.active {
                        color: $white;
                    }
                }
            }
        }
    
        .qrcode-section {
            text-align: center;
            h2 {
                color: red;
                text-align: center;    
                margin: 15px 0;
            }
            > div {
                max-width: 15rem;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        
        .f-n-lks {
            display: flex;
            justify-content: space-between;
            @include maxBreakpoint($bp: 645px) {
                flex-direction: column;
                text-align: center;
            }
        }

    }
}