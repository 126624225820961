@import "../../../styles/animations.scss";
@import "../../../styles/vars.scss";
@import "../../../styles/media.scss";
header {
    // background: red;
    position: sticky;
    top:0;
    color: white;
    height: $header-height;
    // background: linear-gradient(rgba(255, 255, 255, 0.8), transparent);

    background: orange;
    border-bottom: 2px solid white;
    @include transition;
    z-index: 900;

    .header-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo-area {
            a {
                display: flex;
                text-decoration: none;
                color: $primary-clr;
                > .logo {
                    margin-right: 10px;
                }
                
                h1 {
                    font-size: 2rem;
                    letter-spacing: 0.2rem;
                    text-transform: uppercase;
                    display: none;
                    > span {
                        font-size: 2rem;
                    }
                    @include sm {
                        display: block;
                    }
                }
            }
        }
        .btn-area {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }


    &.fixed-hdr {
        position: fixed;
        background: transparent;
        border-bottom: none;
    }
    // for scroll
    &.bg {
        background: $primary-clr;
        border-bottom: 2px solid white;
        img {
            background: transparent;
        }
        h1 {
            // color: #fff;
            color: red;
            > span {
                color: white;
            }
        }
    }

    

}