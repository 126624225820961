@import "../../../../styles/animations.scss";
@import "../../../../styles/vars.scss";
@import "../../../../styles/media.scss";

.mobile-header {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    width:100%;
    position: fixed;
    padding: $con-padding;
    margin: 0 auto;
    @include transition;
    z-index: 900;
    // background: rgba(255, 255, 255, 0.1);
    // background: linear-gradient(red, white);
    background: linear-gradient(rgba(255, 255, 255, 0.8), transparent);
    // background: rgba(0, 0, 0, 0.1);
    h1 {
        color: white;
    }

    .logo-area {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        text-decoration: none;
        color: $primary-clr;
        > .logo {
            margin-right: 10px;
        }
        
        h1 {
            font-size: 2rem;
            letter-spacing: 0.2rem;
            text-transform: uppercase;
            display: none;
            @include sm {
                display: block;
            }
        }
    }

    .btn-area {
        display: flex;
        align-items: center;
        .search-btn-div {
            // border: 0.2px solid darken(white, 20);
            padding: 0.4rem;
            border-radius: 50%;
        }
       
        > div:last-child {
            width: 80px;
            text-align: right;
        }
    }

    &.bg {
        background: $primary-clr;
        img {
            background: transparent;
        }
        h1 {
            color: #fff;
        }
    }
}