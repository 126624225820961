@import "../../../styles/vars.scss";
footer {
    background: $primary-clr;
    color: white;
    > .cover-container {
        background: transparent;
    }
    .quick-links {
        background: lighten($primary-clr, 10);
    }
}