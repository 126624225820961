@import "../../styles/vars";
@import "../../styles/animations";
@import "../../styles/media";

.landing-pg {
    padding: 0;
    .banner {
        width: 100%;
        height: 100vh;
        background-image: url("../../assets/images/gh_sch.jpeg");
        background-position: center;
        background-size: cover;
        position: relative;
        overflow: hidden;
        
        .content {
            width: 90%;
            color: $white;
            position: relative;
            top: 50%;
            left: 5%;
            @include transition;
            // transform: translateY(calc(-50% - 70px - 1rem));
            transform: translateY(calc(-45%));
            > * {
                margin-bottom: 20px;
            }
            h1 {
                font-size: 3rem;
                color: $accent-clr;
                transition: $transition;
                position: relative;
                opacity: 0;
                // @include slideUpWithBg;
                @include slideUp;
                text-transform: uppercase;
                // @include stroke($size: 0.01px, $color: #aaa);
                // background: rgba(255, 255, 255, 0.4);
                background: rgba(0, 0, 0, 0.4);
                padding: 1rem;
            }

            p.cover-summary {
                opacity: 0;
                @include fadeIn;
                // background: rgba(200, 244, 105, 0.1);
                background: rgba(0, 0, 0, 0.5);
                padding: 2rem 1rem;
            }

            .v-btn-div {
                opacity: 0;
                @include slideRight($duration: 0.5s, $delay: 2s);
                > * {
                    display: inline-block;

                }
            }

            @include heightBreakpoint {
                > * {
                    margin-bottom: 0;
                }
                h1 {
                    font-size: 30px;
                    background: none;
                }
                p.cover-summary {
                    // display: none;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }
                .v-btn-div {
                    margin-top: 1rem;
                    > a {
                        font-size: 12px;
                    }
                }
            }
           
        }
    }
}
