@mixin contentSize {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
}
.entry-outlet {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    > header, 
    > footer {
        width: 100%;
        > *.cover-container {
            > * {
                display: block;
                @include contentSize;
            }
        }

        > *:not(.cover-container) {
            @include contentSize;
        }
    }

    > main {
        width: 100%;
        flex-grow: 1;

        > div {
            > *.cover-container {
                > * {
                    @include contentSize;
                }
            }
        }

        > div.cover-container:not(.landing-pg) {
            > * {
                @include contentSize;
            }
        }
    }


   


}