.card-frame {
    line-height: 2.5rem;
    // padding: 5rem 0;
    h1 {
        span {
            line-height: 5rem;
        }
    }
    .read-more-wrapper {
        margin-top: 2rem;
    }
}