// colors
//rgb(200, 244, 105)//accent clr

$accent-clr: #c8f469;
$white: #fff;
$primary-clr: #1E2C38;
$primary-dark: #2b2d42;
$primary-red: #ef233c;
$secondary-red: #d90429;
$bg-clr: #3D5060;
$light-bg-clr: #edf9fb;
$brown-clr: #A42040;
$action-btn-primary-clr: orange;
$action-btn-accent-clr: #FF0201;

$secondary-white-clr: #f5f5f5;
$reg-clr: #333;

$link-clr: #00acca;

$icon-clr: #333;

$transition-speed: 0.3s;

@mixin stroke($color: #000, $size: 1px) {
    text-shadow:
      -#{$size} -#{$size} 0 $color,
       0        -#{$size} 0 $color,
       #{$size} -#{$size} 0 $color,
       #{$size}  0        0 $color,
       #{$size}  #{$size} 0 $color,
       0         #{$size} 0 $color,
      -#{$size}  #{$size} 0 $color,
      -#{$size}  0        0 $color;
  }
@mixin transition($duration: 0.3s) {
    transition: all $duration ease-in-out;
}
$transition: all $transition-speed ease-in-out;


$header-height: 6rem;
// Spacing
$base-padding: 0.75rem;
$base-margin: 0.75rem;
$btn-padding: $base-padding - 0.15  $base-padding;
$con-padding: 0 2rem;


// Borders
$base-border-thickness: 1px;
$base-border-radius: 10px;


// Shadows
$base-box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);

@mixin section-top-bottom-padding {
    padding-top: 4rem;
    padding-bottom: 4rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 2.5rem;
}


// icons 
$icon-size: 20px;