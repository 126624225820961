/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/raleway-v28-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/raleway-v28-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/raleway-v28-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/raleway-v28-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/raleway-v28-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/raleway-v28-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat-h';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/montserrat-v25-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/montserrat-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/montserrat-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/montserrat-v25-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/montserrat-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/montserrat-v25-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/poppins-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/poppins-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/poppins-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/poppins-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/poppins-v20-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins-h';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/poppins-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/poppins-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/poppins-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/poppins-v20-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/poppins-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/poppins-v20-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
}

*, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', 'sans-serif',  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
  font-family: 'Poppins';
}



@import "./styles/utilities.scss";
@import "./styles/media.scss";
@import "./styles/grid.scss";
@import "./styles/components/components.scss";
@import "./styles/vars.scss";


// General styles
//Header
a {
  text-decoration: none;
}
img {
  width: 100%;
}
.logo {
  img {
    border: 1px solid white;
    background: $primary-red;
  }
}

// Containers
.cover-container {
  background: #fff;
  padding: $con-padding;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

h1, h2, h3, h4, h5, h6 {
  // font-family: 'Montserrat-h', 'Poppins-h', sans-serif;
  margin-top: 0.5rem; 
  margin-bottom: 0.5rem; 
}


h1.title {
  font-size: 2rem;  
  // font-family: 'Raleway', sans-serif;
}

h1 {
  font-size: 1.7rem;  
}
h2 {
  font-size: 1.2rem;
}

.icon {
  transition: $transition;
  opacity: 0.6;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}




// Media Queries
*, html {
  @include xs {
    font-size: 14px;
  }
}


// .mobile-menu {
//   @include sm {
//     display: none;
//   }
// }
