@import "../../../../styles/vars";


@mixin cover-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
$padding: 1.5rem;
@mixin content {
    position: relative;
    top: 50%;
    left: 50%;
    // padding: 1.5rem;
    transform: translate(-50%, -50%);
    min-height: 50%;
    max-height: 80%;
    // min-width: 70%;
    width: 80%;
    max-width: 700px;
    background: white;
    box-shadow: 0 0 10px rgba(0,0,0,0.8);
    z-index: 999;
    display: flex;
    flex-direction: column;
}

.AwModal {
    @include cover-bg();
    z-index: 990;
}
.AwModalBackdrop {
    @include cover-bg;
    z-index: 998;
    background-color: rgba(0,0,0,0.5);
}
.AwModalContent {
    @include content;
}



.AwModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: $padding;
    border-bottom: 1px solid #ccc;
    h1 {
        font-size: 1.6rem;
    }
    .icon.close {
        width: 15px;
        height: 15px;
    }

    background: $primary-clr;
    color: white;
}

.AwModalBody {
    overflow-y: auto;
    max-height: 400px;
    display: flex;
    flex-grow: 1;
}

.AwModalFooter {
    // background: red;
    padding: $padding;
    height: 50px;
    display: flex;
    align-items: center;
    border-top: 1px solid #ccc;
}



// Search modal styles
.AwModalSearchContent {
    @include content;
    background: $primary-clr;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: $padding;
    box-shadow: 0 0 10px rgba(200,244,105,0.3);
    p {
        color: white;
        width: 100%;
        padding-top: 15px;
        margin-left: 10px;
        >span {
            color: $accent-clr;
        }
    }
}
.AwModalSearchContentBody {
    // position: relative;
    width: 100%;
    max-width: 480px;
    height: 100%;
}

.AwModalSearchContentCloseArea {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
}