// @import "../../../../styles/grid.scss";
@import "../../../../styles/animations.scss";


@import "../../../../styles/vars.scss";
@import "../../../../styles/media.scss";

@mixin cover-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
$p: 2.5rem;
@mixin hover {
    background: darken(#ddd, 5);
    opacity: 0.8;
}




.mobile-menu-btn-wrapper {
    // .btn-menu {
    //     border: none;
    //     padding: $base-padding;
    // }
    // @include md {
    //     display: none;
    // }
}


.mobile-menu-wrapper {
    @include cover-bg;
    z-index: 990;
    .backdrop {
        @include cover-bg;
        z-index: 998;
        background-color: rgba(0,0,0,0.01);
    }
    .content {
        position: relative;
        right: -100%;
        top: 0;
        transform: translateX(-100%);
        height: 100vh;
        width: 70%;
        max-width: 500px;
        background: white;
        box-shadow: 0 0 10px rgba(0,0,0,0.8);
        z-index: 999;
        display: flex;
        flex-direction: column;
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1.5rem 0;
            padding-left: $p;
            padding-right: $p;
            height: 50px;
        }
        .menus {
            flex-grow: 1;
            font-weight: 500;
            letter-spacing: 0.08rem;
            > .menu {
                padding: 0.25rem 0;
                > *.mobile-menu-link {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0.3rem $p;
                    cursor: pointer;
                    
                    opacity: 0.6;
                    &:hover {
                        @include hover();
                    }
                }
                > *:not(.mobile-menu-link) {
                    .mobile-menu-link {
                        padding: 0.3rem $p;
                        display: block;
                        cursor: pointer;
                        opacity: 0.6;
                        flex-grow: 1;
                    }
                    &:hover {
                        @include hover();
                        .mobile-menu-link {
                            opacity: 1;
                            .icon {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            
        }
    }

    &.show {
        display: block;
        @include slideLeft($duration: 0.3s);
    }
}