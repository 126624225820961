@import "./mixins.scss";
@import "./vars.scss";
// links
$lk-clr: #00acca;
.readmore {
    color: $lk-clr;
    cursor: pointer;
    text-decoration: none;
}

a.a-link {
    color: $lk-clr;
    cursor: pointer;
    position: relative;
    @include transition;
    &:hover {
        text-decoration: underline;
    }
}

// buttons
.btn-menu {
    @include btn($color: #ddd);
    padding: 0.7rem 0.75rem;
    color: darken(white, 30);
    background: darken($primary-clr, 10);

    &:hover {
        color: darken(white, 10);
        background: darken($primary-clr, 5);
    }
}



.btn-donate {
    @include action($color: $action-btn-accent-clr);
}
.btn-donate-sm {
    @include action($color: $action-btn-accent-clr);
    padding: $btn-padding;
    text-transform: capitalize;
    border-radius: 0;
    padding: 0.6rem 0.75rem;//  0.4rem 0.75rem;
    &:hover {
        background: $action-btn-primary-clr;
    }
}
.btn-donate-sm.sec {
    @include action($color: $action-btn-primary-clr);
    border-radius: 0;
}

.btn-volunteer {
    @include action;
}