@import "../../../styles/vars";



.form {
    line-height: 3rem;
    
    max-width: 700px;
    input, textarea {
        width: 100%;
        padding: 0.75rem 1rem;
    }
    textarea {
        min-height: 150px;
    }

    button {
        width: 100%;
        padding: 0.75rem 1rem;
        background: $primary-clr;
        color: darken(white, 20);
        outline: none;
        border: none;
        cursor: pointer;
        @include transition;
        &:hover {
            background: lighten($primary-clr, 10);
            color: white;
        }
        &:disabled,
        &[disabled] {
            background: lighten($primary-clr, 20);
            color: darken(white, 10);
            cursor:not-allowed;
        }
    }

    &.vertical {
        label {
            display: block;
        }
    }
}